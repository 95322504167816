<template>
  <div>
    <page-header name="Solicitações de Desistência" />
    <Grid
      :fields="fields"
      :items="items"
      :show-search="false"
      @onChangePage="getUnsubscriptionRequests"
    >
      <template #cell(informations)="data">
        <b>CPF:</b> {{ data.item.cpf }}<br >
        <b>Nome:</b> {{ data.item.name }}<br >
        <b>Email:</b> {{ data.item.email }}
      </template>
      <template #cell(action)="data">
        <b-button
          v-if="data.item.prize_draws.length > 0"
          variant="gradient-info"
          class="btn-icon mr-2"
          @click="showPrizeDraws(data.item)"
        >
          <feather-icon icon="InfoIcon" />
        </b-button>
        <b-button
          v-if="data.item.active === true"
          variant="gradient-primary"
          class="btn-icon"
          @click="confirmUnsubscribe(data.item)"
        >
          <feather-icon icon="CheckCircleIcon" />
          Aprovar
        </b-button>
        <b-badge v-else variant="danger">
          Desativado
        </b-badge>
      </template>
    </Grid>

    <b-modal
      v-model="showModalPrizeDraws"
      title="Sorteios cancelados"
      ok-only
    >
      <p><b>{{ current.name }}</b> deixou de participar dos seguintes sorteios.</p>
      <b-list-group v-if="current.prize_draws">
        <b-list-group-item v-for="prizeDraw of current.prize_draws"
                           :key="prizeDraw.id"
        >{{ prizeDraw.name }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>
<script>

import { getUserData } from '@/auth/utils'
import { BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'ParticipantList',
  components: {
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      userData: null,
      current: {},
      showModalPrizeDraws: false,
      search: {
        search: '',
        type: '',
      },
      fields: [
        {
          key: 'informations',
          label: 'Informações do Participante',
          class: 'w-40',
        },
        {
          key: 'reason',
          label: 'Motivo',
          class: 'w-40',
        },
        {
          key: 'action',
          label: 'Ação',
          class: 'w-20',
        },
      ],
      items: [],
    }
  },
  async mounted() {
    this.userData = await getUserData()
    this.getUnsubscriptionRequests()
  },
  methods: {
    async getUnsubscriptionRequests() {
      if (!this.userData || !this.userData.current_promotion || !this.userData.current_promotion.uuid) {
        this.$notify.error('Antes de continuar é necessário selecionar uma promoção.')
        return false
      }

      this.items = await this.$http.get(
        `promotions/${this.userData.current_promotion.uuid}/unsubscribe-requests`,
      )
      return true
    },
    async confirmUnsubscribe(data) {
      this.$bvModal.msgBoxConfirm('Este procedimento é irreversível. Confirma a exclusão do participante?', {
        title: 'Confirma a exclusão do participante?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim, confirmo!',
        cancelTitle: 'Cancelar',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      }).then(async confirm => {
        if (confirm) {
          await this.$http.post(`approve-unsubscribe/${data.id}`, [])
          this.$notify.success('Solicitação aprovada com sucesso.')
          this.getUnsubscriptionRequests()
        }
      })
    },
    showPrizeDraws(data) {
      this.showModalPrizeDraws = true
      this.current = data
    },
  },
}
</script>

<style scoped>

</style>
